'use client'

import { useEffect } from 'react'

import GenericError from '@guestyci/foundation-legacy/GenericError';
import Section from '@guestyci/foundation-legacy/Section';

export default function GlobalError({ error }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <Section style={{ height: '100vh' }} className="w-fill" bgColor="white" col>
      <GenericError className="text-gray-dark" />
    </Section>
  )
}